import { useStores } from '../../hooks/use-stores';

const translate = (englishDescription) =>
  englishDescription
    .replace('at', 'a')
    .replace('month', 'mes')
    .replace('year', 'año')
    .replace('Unused time on', 'Tiempo acreditado en')
    .replace('after', 'despues del');

const mexicanDateAndTimeFormat = (unixTimestamp) =>
  new Date(unixTimestamp * 1000)
    .toLocaleString('es-MX')
    .split(':')
    .splice(0, 2)
    .join(':');

const mexicanDateFormat = (unixTimestamp) =>
  new Date(unixTimestamp * 1000).toLocaleDateString('es-MX');

function PaymentHistory() {
  const { featureStore } = useStores();

  return (
    <div className="shadow overflow-hidden sm:rounded-md mt-6">
      <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
        <h2
          id="billing-history-heading"
          className="text-lg leading-6 font-medium text-gray-900"
        >
          Historial de pagos
        </h2>
      </div>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden border-t border-gray-200">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Fecha
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Descripción
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Periodo
                    </th>
                    {/*
                                  `relative` is added here due to a weird bug in Safari that causes `sr-only` headings to introduce overflow on the body on mobile.
                                */}
                    <th
                      scope="col"
                      className="relative px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      <span className="sr-only">Ver factura</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {featureStore.state.lastInvoices.map((invoiceItem) => (
                    <tr key={invoiceItem.id}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        <time dateTime={invoiceItem.created}>
                          {mexicanDateAndTimeFormat(invoiceItem.created)}
                        </time>
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500">
                        {translate(invoiceItem.description)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {mexicanDateFormat(invoiceItem.period.start)} -{' '}
                        {mexicanDateFormat(invoiceItem.period.end)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <a
                          href={invoiceItem.pdfLink}
                          target="_blank"
                          className="text-blue-600 hover:text-blue-900 text-sm cursor-pointer"
                          rel="noreferrer"
                        >
                          Descargar
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentHistory;
