import { observer } from 'mobx-react-lite';

function ContentHeader({ title, description, searchPlaceHolder, children }) {
  return (
    <div className="py-5 px-4 sm:px-0 border-b border-gray-200">
      <div className="-mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
        <div className="mt-4">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {title}
          </h3>
          <p className="mt-1 text-sm text-gray-500">{description}</p>
        </div>
        <div className="mt-4 flex-shrink-0">{children}</div>
      </div>
    </div>
  );
}

export default observer(ContentHeader);
