/* eslint-disable react/jsx-props-no-spreading */
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/solid';
import { runInAction } from 'mobx';

import { Observer, useLocalObservable } from 'mobx-react-lite';
import propTypes from 'prop-types';
import { useEffect, useLayoutEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from '../utils/classNames';
import ActionMenu from './ActionMenu';

function TH({
  children,
  className = '',
  linebreak,
  sortable,
  isActive,
  type,
  ...props
}) {
  const defaultClasses =
    'px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider';
  const newClassName = classNames(
    className,
    isActive ? 'bg-zinc-100' : 'bg-gray-50',
    type === 'number' ? 'text-right' : '',
    defaultClasses,
    linebreak ? 'whitespace-normal' : 'whitespace-nowrap'
  );

  return (
    <th scope="col" className={newClassName} {...props}>
      {children}
    </th>
  );
}

const getStripedIndex = (index) =>
  index % 2 === 0 ? 'bg-white' : 'bg-gray-50';
const getHoverStriped = (index) =>
  index % 2 === 0
    ? 'bg-white hover:bg-gray-100'
    : 'bg-gray-50 hover:bg-gray-100';

const getStripedClassName = (index, hover) =>
  hover ? getHoverStriped(index) : getStripedIndex(index);

function SortableIndicator({ onClick, isActive, sortDirection }) {
  const arrowUpClassName = classNames(
    isActive && sortDirection === 'ASC'
      ? 'text-gray-500'
      : 'text-gray-300 hover:text-gray-400',
    'h-5 w-5 px-1 pt-1 relative bottom-[-1px]'
  );

  const arrowDownClassName = classNames(
    isActive && sortDirection === 'DESC'
      ? 'text-gray-500'
      : 'text-gray-300 hover:text-gray-400',
    'h-5 w-5 px-1 pb-1 relative top-[-1px]'
  );

  const containerClassName = classNames(
    !isActive && 'hidden',
    'flex-col absolute right-[-16px]  group-hover:flex'
  );

  const handleSortDirectionChange = (type) => (evt) => {
    evt.stopPropagation();
    onClick(evt, type);
  };

  return (
    <span className={containerClassName}>
      <ArrowUpIcon
        className={arrowUpClassName}
        onClick={handleSortDirectionChange('ASC')}
      />
      <ArrowDownIcon
        className={arrowDownClassName}
        onClick={handleSortDirectionChange('DESC')}
      />
    </span>
  );
}

const classNameByType = (linebreak) => ({
  undefined: `px-6 py-4 ${
    linebreak ? 'whitespace-normal' : 'whitespace-nowrap'
  } text-sm font-medium text-gray-900`,
  date: `px-6 py-4 ${
    linebreak ? 'whitespace-normal' : 'whitespace-nowrap'
  } text-sm font-medium text-gray-900`,
  number: `px-6 py-4 ${
    linebreak ? 'whitespace-normal' : 'whitespace-nowrap'
  } text-sm font-medium text-gray-900 text-right`
});

function Row({
  row,
  onRowClick,
  striped,
  hover,
  rowIndex,
  rowKey,
  columns,
  hasActions,
  rowMenuConfig
}) {
  const handleRowClick = (evt) => {
    if (onRowClick) {
      if (
        typeof evt.target.className === 'string' &&
        evt.target.className.indexOf('action-related') === -1
      ) {
        onRowClick(evt, row);
      }
    }
  };

  return (
    <tr
      onClick={handleRowClick}
      className={classNames(
        striped ? getStripedClassName(rowIndex, hover) : 'bg-white',
        hover && 'cursor-pointer',
        hover && !striped && 'hover:bg-gray-100'
      )}
    >
      {columns.map((column) => (
        <td
          key={`${row[rowKey]}-${column.key}`}
          className={classNameByType(column.linebreak)[column.type]}
        >
          {column.template
            ? column.template(row[column.key], row)
            : row[column.key]}
        </td>
      ))}
      {hasActions && (
        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
          <ActionMenu menuConfig={rowMenuConfig} row={row} />
        </td>
      )}
    </tr>
  );
}

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

function Table({
  columns = [],
  rowMenuConfig,
  rowKey,
  rows = [],
  striped = false,
  hover = false,
  onRowClick
}) {
  const state = useLocalObservable(() => ({
    sortByColumn: undefined,
    rows: [],
    sortDirection: 'ASC',
    setFilterByRFC: '',
    setSortByColumn: (column, type) => {
      if (!type) {
        if (
          !state.sortByColumn ||
          state.sortByColumn?.key !== column.key ||
          state.sortDirection === 'DESC'
        ) {
          state.sortDirection = 'ASC';
        } else {
          state.sortDirection = 'DESC';
        }
      } else {
        state.sortDirection = type;
      }
      state.sortByColumn = column;
    },
    setRows: (rawRows) => {
      state.rows = rawRows;
    },
    get sortedRows() {
      if (
        !state.sortByColumn &&
        (state.setFilterByRFC === '' || state.setFilterByRFC === null)
      ) {
        return state.rows;
      }

      let filteredRows = state.rows;

      if (state.setFilterByRFC !== '' && state.setFilterByRFC !== null) {
        filteredRows = state.rows.filter(
          (row) =>
            row.name === state.setFilterByRFC ||
            row.legalRepresentorRFC === state.setFilterByRFC
        );
      }

      if (!state.sortByColumn) {
        return filteredRows;
      }

      const sortKey = state.sortByColumn.key;
      const fieldType = state.sortByColumn.type;
      const { sortDirection } = state;

      const sortedRows = filteredRows.slice().sort((row, nextRow) => {
        if (fieldType === 'date') {
          if (sortDirection === 'ASC') {
            return new Date(row[sortKey]) > new Date(nextRow[sortKey]) ? 1 : -1;
          }
          return new Date(row[sortKey]) < new Date(nextRow[sortKey]) ? 1 : -1;
        }
        if (sortDirection === 'ASC') {
          return row[sortKey] > nextRow[sortKey] ? 1 : -1;
        }
        return row[sortKey] < nextRow[sortKey] ? 1 : -1;
      });

      return sortedRows;
    }
  }));

  const query = useQuery();

  useEffect(() => {
    runInAction(() => {
      state.setFilterByRFC = query.get('name');
    });
  }, [query]);

  useLayoutEffect(() => {
    state.setRows(rows);
    return () => {
      state.setRows([]);
    };
  }, [rows]);

  const hasActions = rowMenuConfig?.actions.length > 0;

  const handleSortClick = (column) => (evt, direction) => {
    if (!column.sortable) return;
    state.setSortByColumn(column, direction);
  };

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <Observer>
                    {() =>
                      columns.map((column) => (
                        <TH
                          key={column.key}
                          isActive={state.sortByColumn?.key === column.key}
                          sortable={column.sortable}
                          onClick={handleSortClick(column)}
                          className="group cursor-pointer"
                        >
                          <div className="flex items-center justify-between relative">
                            {column.label}{' '}
                            {column.sortable && (
                              <SortableIndicator
                                isActive={
                                  state.sortByColumn?.key === column.key
                                }
                                sortDirection={state.sortDirection}
                                onClick={handleSortClick(column)}
                                column={column}
                              />
                            )}
                          </div>
                        </TH>
                      ))
                    }
                  </Observer>
                  {hasActions && (
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Edit</span>
                    </th>
                  )}
                </tr>
              </thead>

              <tbody
                className={striped ? '' : 'bg-white divide-y divide-gray-200'}
              >
                <Observer>
                  {() =>
                    state.sortedRows.map((row, rowIndex) => (
                      <Row
                        key={row[rowKey]}
                        row={row}
                        rowKey={rowKey}
                        rowIndex={rowIndex}
                        striped={striped}
                        hover={hover}
                        columns={columns}
                        hasActions={hasActions}
                        rowMenuConfig={rowMenuConfig}
                        onRowClick={onRowClick}
                      />
                    ))
                  }
                </Observer>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

const { arrayOf, string, number, oneOfType, object, bool, func, shape } =
  propTypes;

const keyType = oneOfType([string, number]);
const labelType = oneOfType([string, number]);

Table.propTypes = {
  columns: arrayOf(
    shape({
      key: keyType,
      label: labelType
    })
  ),
  actions: arrayOf(
    shape({
      key: keyType,
      label: labelType,
      validator: func,
      onClick: func
    })
  ),
  // eslint-disable-next-line react/forbid-prop-types
  rows: arrayOf(object),
  striped: bool
};

export default Table;
