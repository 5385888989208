import React, { useState } from 'react';
import {
  Disclosure,
  Menu,
  RadioGroup,
  Switch,
  Transition
} from '@headlessui/react';
import ReactMarkdown from 'react-markdown';

import classNames from '../../utils/classNames';
import PrimaryButton from '../../Primitives/PrimaryButton';
import { useStores } from '../../hooks/use-stores';
import http from '../../transports/http';

const getCurrentPlan = (currentPlan, plans) =>
  plans.find((plan) => plan.name === currentPlan);

const calculateDiscountedAnnualPrice = (pricePerMonth, discount) =>
  Math.round((pricePerMonth - pricePerMonth * discount) * 12);

function Plan() {
  const { featureStore } = useStores();
  const plans = featureStore.publicPlans;

  const currentPlan = getCurrentPlan(featureStore.state.account.plan, plans);

  const [selectedPlan, setSelectedPlan] = useState(currentPlan);
  const [annualBillingEnabled, setAnnualBillingEnabled] = useState(false);

  if (
    featureStore.state.account.plan === 'unlimited' ||
    featureStore.state.account.plan === 'none'
  ) {
    return (
      <div className="shadow overflow-hidden sm:rounded-md">
        <div className="bg-white text-center py-6 px-4 space-y-6 sm:p-6">
          Usted tiene un plan individual que no se puede modificar desde esta
          pagina, favor de ponerse en contacto con nosotros si desea un cambio.
        </div>
      </div>
    );
  }
  return (
    <form
      className="shadow overflow-hidden sm:rounded-md"
      method="POST"
      action={`${http.endpoint}/payments/stripe/create-checkout-session`}
    >
      <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
        <RadioGroup value={selectedPlan} onChange={setSelectedPlan}>
          <RadioGroup.Label className="sr-only">Pricing plans</RadioGroup.Label>
          <div className="relative bg-white rounded-md -space-y-px">
            {plans.map((plan, planIdx) => (
              <React.Fragment key={plan.name}>
                <RadioGroup.Option
                  key={plan.name}
                  value={plan}
                  className={({ checked }) =>
                    classNames(
                      planIdx === 0 ? 'rounded-md mb-4' : '',
                      planIdx === 1 ? 'rounded-tl-md rounded-tr-md mb-4' : '',
                      planIdx === plans.length - 1
                        ? 'rounded-bl-md rounded-br-md'
                        : '',
                      checked
                        ? 'bg-blue-50 border-blue-200'
                        : 'border-gray-200',
                      'relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-3 focus:outline-none'
                    )
                  }
                >
                  {({ active, checked }) => (
                    <>
                      <div className="flex items-center text-sm">
                        <span
                          className={classNames(
                            checked
                              ? 'bg-blue-500 border-transparent'
                              : 'bg-white border-gray-300',
                            active ? 'ring-2 ring-offset-2 ring-gray-900' : '',
                            'h-4 w-4 rounded-full border flex items-center justify-center'
                          )}
                          aria-hidden="true"
                        >
                          <span className="rounded-full bg-white w-1.5 h-1.5" />
                        </span>
                        <RadioGroup.Label
                          as="span"
                          className="ml-3 font-medium text-gray-900"
                        >
                          {plan.label}{' '}
                          {plan === currentPlan && (
                            <span className="block text-xs text-gray-400">
                              actualmente elegido
                            </span>
                          )}
                        </RadioGroup.Label>
                      </div>
                      <RadioGroup.Description className="ml-6 pl-1 flex items-center justify-center text-sm md:ml-0 md:pl-0 md:text-center">
                        <span
                          className={classNames(
                            checked ? 'text-blue-900' : 'text-gray-900',
                            'font-medium mr-1'
                          )}
                        >
                          ${plan.pricePerMonth} / mes
                        </span>
                        {plan.pricePerMonth > 0 && (
                          <span
                            className={
                              checked ? 'text-blue-700' : 'text-gray-500'
                            }
                          >
                            ($
                            {calculateDiscountedAnnualPrice(
                              plan.pricePerMonth,
                              plan.annualDiscount
                            )}{' '}
                            / año)
                          </span>
                        )}
                      </RadioGroup.Description>
                      <RadioGroup.Description
                        className={classNames(
                          checked ? 'text-blue-700' : 'text-gray-500',
                          'ml-6 pl-1 flex items-center justify-end text-sm md:ml-0 md:pl-0 md:text-right'
                        )}
                      >
                        {plan.description}
                      </RadioGroup.Description>
                      {plan.stripePriceIds &&
                        checked &&
                        (annualBillingEnabled ? (
                          <input
                            type="hidden"
                            name="lookup_key"
                            value={plan.stripePriceIds.yearly}
                          />
                        ) : (
                          <input
                            type="hidden"
                            name="lookup_key"
                            value={plan.stripePriceIds.monthly}
                          />
                        ))}
                    </>
                  )}
                </RadioGroup.Option>
                {planIdx === 0 && (
                  <span className="font-medium block pt-4 text-sm pb-2 text-gray-500">
                    Planes con costo mensual o descuento al pagar anualmente
                  </span>
                )}
              </React.Fragment>
            ))}
          </div>
        </RadioGroup>

        <Switch.Group as="div" className="flex items-center">
          <Switch
            checked={annualBillingEnabled}
            onChange={setAnnualBillingEnabled}
            className={classNames(
              annualBillingEnabled ? 'bg-blue-500' : 'bg-gray-200',
              'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 transition-colors ease-in-out duration-200'
            )}
          >
            <span
              aria-hidden="true"
              className={classNames(
                annualBillingEnabled ? 'translate-x-5' : 'translate-x-0',
                'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
              )}
            />
          </Switch>
          <Switch.Label as="span" className="ml-3">
            <span className="text-sm font-medium text-gray-900">
              Facturación anual
            </span>
          </Switch.Label>
        </Switch.Group>
      </div>
      <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <PrimaryButton disabled={currentPlan === selectedPlan} type="submit">
          Guardar
        </PrimaryButton>
      </div>
    </form>
  );
}

export default Plan;
