/* eslint-disable class-methods-use-this */
import { action, makeAutoObservable, runInAction } from 'mobx';
import http from '../transports/http';

const DEFAULT_STATE = {
  stats: {},
  account: {},
  planLimitationsTypes: [],
  lastInvoices: [],
  canAddCertificates: false,
  canDeleteCertificates: false,
  canRenewCertificates: false,
  isFetching: false
};

class FeatureStore {
  state = { ...DEFAULT_STATE };

  constructor() {
    makeAutoObservable(this);
  }

  fetch = async () => {
    this.setFetching(true);
    runInAction(() => {
      this.state.planLimitationsTypes = [];
    });
    const [status, response] = await http.get('/session/features');
    if (status === 200 && response?.planLimitationsTypes) {
      runInAction(() => {
        Object.entries(response.planLimitationsTypes).forEach((limitObject) => {
          const [internalLabel, limit] = limitObject;
          if (typeof limit === 'object') {
            this.state.planLimitationsTypes.push(limit);
          }
        });
        this.state.lastInvoices = response.lastInvoices;
      });
    }

    this.setFetching(false);
  };

  setFeatures = action((features) => {
    Object.assign(this.state, features);
    // this.state = features;
  });

  reset = action(() => {
    this.state = { ...DEFAULT_STATE };
  });

  setFetching = action((value) => {
    this.state.isFetching = value;
  });

  get publicPlans() {
    return this.state.planLimitationsTypes.filter((limit) => limit.public);
  }
}

export default FeatureStore;
