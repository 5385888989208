import {
  CheckCircleIcon,
  ExclamationIcon,
  InformationCircleIcon,
  XIcon
} from '@heroicons/react/outline';

import { observer } from 'mobx-react-lite';
import { useStores } from '../hooks/use-stores';

function Alert({ alert, onClose }) {
  if (alert.type === 'success') {
    return (
      <div className=" bg-green-100 flex p-4 w-full flex self-center">
        <div className="flex-shrink-0">
          <CheckCircleIcon
            className="h-5 w-5 text-green-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-green-700">{alert.title}</h3>
          <div className="mt-2 text-sm text-green-700">
            <p>{alert.body}</p>
          </div>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              onClick={onClose}
              className="inline-flex bg-green-100 rounded-md p-1.5 text-green-500 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
            >
              <span className="sr-only">Dismiss</span>
              <XIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (alert.type === 'information') {
    return (
      <div className=" bg-blue-100 flex p-4 w-full flex self-center">
        <div className="flex-shrink-0">
          <InformationCircleIcon
            className="h-5 w-5 text-blue-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-blue-700">{alert.title}</h3>
          <div className="mt-2 text-sm text-blue-700">
            <p>{alert.body}</p>
          </div>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              onClick={onClose}
              className="inline-flex bg-blue-100 rounded-md p-1.5 text-blue-500 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-50 focus:ring-blue-600"
            >
              <span className="sr-only">Dismiss</span>
              <XIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (alert.type === 'warning') {
    return (
      <div className="flex bg-yellow-50 p-4 w-full flex self-center">
        <div className="flex-shrink-0">
          <ExclamationIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-800">{alert.title}</h3>
          <div className="mt-2 text-sm text-yellow-700">
            <p>{alert.getBody({ onClose })}</p>
          </div>
        </div>

        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              onClick={onClose}
              className="inline-flex bg-yellow-50 rounded-md p-1.5 text-yellow-500 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-yellow-50 focus:ring-yellow-600"
            >
              <span className="sr-only">Dismiss</span>
              <XIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function YieldAlerts() {
  const { alertsStore } = useStores();
  return alertsStore.state.items.map((alert) => (
    <Alert key={alert.key} alert={alert} onClose={alertsStore.close(alert)} />
  ));
}

export default observer(YieldAlerts);
