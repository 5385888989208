/* eslint-disable no-undef */
// we don't send the application/json to have more efficient pre-flight
// read: https://stackoverflow.com/a/12021982
// read: https://www.npmjs.com/package/body-parser#type
const defaultRequestOptions = {
  headers: {
    'Content-Type': 'text/plain'
  },
  credentials: 'include',
  mode: 'cors',
  referrerPolicy: 'no-referrer-when-downgrade'
};

class HTTP {
  constructor(endpoint) {
    this.endpoint = endpoint;
    this.loading = false;
    this.pendingRequests = 0;
  }

  setLoader = (getRef) => {
    if (!this.loader) {
      setTimeout(() => {
        this.loader = getRef();
      }, 0);
    }
  };

  startLoading = () => {
    this.pendingRequests += 1;

    if (this.pendingRequests === 1) {
      setTimeout(() => {
        this.loader.current.continuousStart(0, 250);
      }, 0);
    }
  };

  stopLoading = () => {
    setTimeout(() => {
      this.pendingRequests -= 1;

      if (this.pendingRequests === 0) {
        this.loader.current.complete();
      }
    }, 50);
  };

  post = (path, body) => this.request('POST', path, body);

  patch = (path, body) => this.request('PATCH', path, body);

  put = (path, body) => this.request('PUT', path, body);

  get = async (path) => this.request('GET', path);

  delete = async (path) => this.request('DELETE', path);

  request = async (method = 'GET', path, body) => {
    this.startLoading(method, path);
    try {
      const options = {
        method: method.toUpperCase(),
        ...defaultRequestOptions
      };

      if (body) {
        options.body = JSON.stringify(body);
      }

      const response = await fetch(`${this.endpoint}${path}`, options);
      if (response.status === 401) {
        return window.stores.sessionStore.setAuthenticated(false);
      }

      const responseBody = await HTTP.handleResponse(response);

      return [response.status, responseBody];
    } catch (ex) {
      return [500, ex];
    } finally {
      this.stopLoading();
    }
  };

  static handleResponse(response) {
    if (response) {
      const [contentType] = response.headers.get('Content-Type').split('; ');
      const updatedFeaturesString = response.headers.get('x-features');

      if (updatedFeaturesString) {
        const updatedFeatures = JSON.parse(updatedFeaturesString);
        window.stores.featureStore.setFeatures(updatedFeatures);
      }

      if (contentType === 'application/json') {
        return response.json();
      }

      return response.text();
    }

    throw new Error('No Response');
  }
}

const endpoint = process.env.REACT_APP_BACKEND_URL || '//localhost:4000';
const http = new HTTP(endpoint);

export default http;
