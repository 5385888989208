function getInitials(name = '') {
  let initials = 'X';

  const nameSplit = name.split(' ');

  if (nameSplit.length === 1) {
    [initials] = nameSplit[0] || 'X';
  }
  if (nameSplit.length > 1) {
    const [firstName, ...lastNames] = nameSplit;
    initials = `${firstName[0]}${lastNames.slice(-1)[0][0]}`;
  }

  return initials.toUpperCase();
}

export default getInitials;
