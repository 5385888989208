/* eslint-disable no-undef */
import { observer } from 'mobx-react-lite';
import { TailSpin } from 'react-loading-icons';
import { PlusIcon } from '@heroicons/react/outline';

import PrimaryButton from '../Primitives/PrimaryButton';
import { useStores } from '../hooks/use-stores';
import classNames from '../utils/classNames';
import validatePassword from '../Modals/validatePassword';

const uploadWithoutPassword = (successCallback) =>
  function ({ onClose }) {
    const handleConfirmationClick = () => {
      onClose();
      successCallback();
    };
    return (
      <>
        No se pudo verificar que usted está autorizado para renovar el
        certificado. Solo le podemos ofrecer las notificaciones al vencer.
        <br />
        Usted podra volver en cualquier momento para agregar la autorizacion y
        aprovechar despues de la renovacion del certificado ante el SAT si su
        Membresia en nuestra plataforma lo incluye.
        <div className="mt-4">
          <div className="-mx-2 -my-1.5 flex">
            <button
              type="button"
              onClick={handleConfirmationClick}
              className="bg-yellow-50 px-2 py-1.5 rounded-md text-sm font-medium text-yellow-800 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-yellow-50 focus:ring-yellow-600"
            >
              Confirmar y subir
            </button>
          </div>
        </div>
      </>
    );
  };

function AddCertificateButton() {
  const { certificatesStore, featureStore } = useStores();

  const handleCertificateUpload = (evt) => {
    const reader = new FileReader();

    reader.addEventListener('load', async () => {
      if (reader.result) {
        try {
          const [, certificate] = reader.result.split(';base64,');

          const certificatePEM = `-----BEGIN CERTIFICATE-----\r\n${certificate}\r\n-----END CERTIFICATE-----\r\n`;

          const password = await validatePassword({
            certificate: certificatePEM
          });

          if (password) {
            certificatesStore.create(reader.result, password);
          } else {
            throw new Error('InvalidPassword');
          }
        } catch (ex) {
          window.stores.alertsStore.warn({
            title:
              'La autorizacion para renovar el certificado no fue recibida',
            getBody: uploadWithoutPassword(() => {
              certificatesStore.create(reader.result);
            })
          });
        }
      }
    });

    reader.readAsDataURL(evt.target.files[0]);
  };

  const handleAddButtonClick = () => {
    const fileElement = document.createElement('input');
    fileElement.type = 'file';
    fileElement.multiple = false;
    fileElement.onchange = handleCertificateUpload;
    fileElement.click();
  };

  const isDisabled =
    !featureStore.state.canAddCertificates || certificatesStore.state.isPosting;

  const prefixSymbolClasses = classNames(
    'h-5 w-5 mr-2',
    isDisabled ? 'text-gray-400' : 'text-white'
  );

  return (
    <PrimaryButton disabled={isDisabled} onClick={handleAddButtonClick}>
      {certificatesStore.state.isPosting ? (
        <TailSpin
          height={16}
          width={24}
          className="mr-2 "
          stroke="#BBB"
          strokeWidth={4}
        />
      ) : (
        <PlusIcon
          className={prefixSymbolClasses}
          width={16}
          aria-hidden="true"
          style={{ width: 24 }}
        />
      )}{' '}
      Agregar Certificado
    </PrimaryButton>
  );
}

export default observer(AddCertificateButton);
