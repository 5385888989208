/* eslint-disable no-undef */
import { action, makeAutoObservable } from 'mobx';
import http from '../transports/http';
import clientEvents from '../transports/clientEvents';

const DEFAULT_STATE = {
  authenticated: false
};

class SessionStore {
  state = { ...DEFAULT_STATE };

  constructor() {
    this.evaluateDocumentCookie();
    makeAutoObservable(this);
  }

  evaluateDocumentCookie = () => {
    const isLoggedInCookieValue = SessionStore.getIsLoggedInCokieValue();

    if (isLoggedInCookieValue && isLoggedInCookieValue !== '') {
      this.setAuthenticated(true);
    } else {
      this.setAuthenticated(false);
    }
  };

  reset = () => {
    this.state = { ...DEFAULT_STATE };
  };

  get isAuthenticated() {
    return !!this.state.authenticated;
  }

  // eslint-disable-next-line class-methods-use-this
  signUpUser = async (data) => {
    const [status, response] = await http.post('/session/signup', data);

    if (status !== 200 || response?.message !== 'signedUpSuccessfully') {
      throw new Error(response?.message || 'cannotSignUp');
    }
  };

  logout = async () => {
    await http.post('/session/logout', {});
    this.setAuthenticated(false);
    clientEvents.reset();
  };

  signInUser = async (data) => {
    const [status, response] = await http.post('/session/login', data);

    if (status === 200 && response.message === 'loggedInSuccessfully') {
      this.setAuthenticated(true);
      clientEvents.identify(SessionStore.getIsLoggedInCokieValue());
    } else {
      this.setAuthenticated(false);
      clientEvents.reset();
      if (response.message) {
        throw new Error(response.message);
      }
      throw new Error('NoMessageFromLoginResponse');
    }
  };

  setAuthenticated = action((val) => {
    this.state.authenticated = val;
  });

  static getIsLoggedInCokieValue() {
    const isLoggedInCookieValue = document.cookie
      ?.split('isLoggedIn=')[1]
      ?.split(';')[0];

    return isLoggedInCookieValue;
  }
}

export default SessionStore;
