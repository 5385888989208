import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import { StoresProvider } from './hooks/use-stores';
import Authentication from './Components/Authentication';
import LandingLayout from './Layouts/LandingLayout';
import ErrorAlert from './Components/ErrorAlert';
import AuthenticatedAppLayout from './Layouts/AuthenticatedAppLayout';
import Reminders from './Apps/Reminders';
import Account from './Apps/Account';
import clientEvents from './transports/clientEvents';

function Landing() {
  return (
    <Routes>
      <Route path="/*" element={<Navigate replace to="/" />} />
      <Route path="/" element={<LandingLayout />} />
      <Route
        path="/signup-error"
        element={
          <LandingLayout>
            <ErrorAlert
              className="absolute mt-4"
              title={<strong>There was an error signing you up</strong>}
            >
              Please try again or come back later.
            </ErrorAlert>
          </LandingLayout>
        }
      />
      <Route
        path="/signin-error"
        element={
          <LandingLayout>
            <ErrorAlert
              className="absolute mt-4"
              title={<strong>There was an error while signing you in</strong>}
            >
              Please try again or come back later.
            </ErrorAlert>
          </LandingLayout>
        }
      />
    </Routes>
  );
}

function App() {
  useEffect(() => {
    clientEvents.init();
  });

  return (
    <StoresProvider>
      <BrowserRouter>
        <Authentication fallback={Landing}>
          <AuthenticatedAppLayout>
            <Routes>
              <Route
                exact
                path="/"
                element={<Navigate replace to="/reminders/certificates" />}
              />
              <Route path="/reminders/*" element={<Reminders />} />
              <Route path="/account" element={<Account />} />
            </Routes>
          </AuthenticatedAppLayout>
        </Authentication>
      </BrowserRouter>
    </StoresProvider>
  );
}

export default App;
