import { useStores } from '../../hooks/use-stores';
import PrimaryButton from '../../Primitives/PrimaryButton';
import Plan from './Plan';
import PaymentHistory from './PaymentHistory';

/* eslint-disable jsx-a11y/label-has-associated-control */
function Form() {
  const { featureStore } = useStores();
  return (
    <>
      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Datos personales
              </h3>
              <p className="mt-1 text-sm text-gray-600">...</p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form action="#" method="POST">
              <div className="shadow overflow-hidden sm:rounded-md">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12 lg:col-span-6">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Nombre completo
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        defaultValue={featureStore.state.account.name}
                        autoComplete="given-name"
                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-12 lg:col-span-6">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Correo electronico
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        defaultValue={featureStore.state.account.email}
                        autoComplete="email"
                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <PrimaryButton type="submit">Guardar</PrimaryButton>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Membresia
              </h3>
              <p className="mt-1 text-sm text-gray-600">...</p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <Plan />
            {featureStore.state.lastInvoices.length > 0 && <PaymentHistory />}
          </div>
        </div>
      </div>
    </>
  );
}

export default Form;
