import { Fragment } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { observer } from 'mobx-react-lite';

import { Link, useNavigate } from 'react-router-dom';
import classNames from '../utils/classNames';
import { useStores } from '../hooks/use-stores';
import getInitials from '../utils/getInitials';

const colors = [
  '#FFB900',
  '#D83B01',
  '#B50E0E',
  '#E81123',
  '#B4009E',
  '#5C2D91',
  '#0078D7',
  '#00B4FF',
  '#008272'
];

function calculateColor(str) {
  let sum = 0;
  Object.values(str.split('')).forEach((value, index) => {
    sum += str.charCodeAt(index);
  });
  return sum % colors.length;
}

function UserMenu() {
  const navigate = useNavigate();

  const { sessionStore, featureStore } = useStores();

  const handleLogout = async () => {
    await sessionStore.logout();
    navigate('/');
  };

  const initials = getInitials(featureStore.state.account.name);

  return (
    <Menu as="div" className="ml-3 relative" style={{ top: 2 }}>
      <div>
        <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
          <span className="sr-only">Open user menu</span>

          <span
            className="h-8 w-8 rounded-full flex items-center justify-center text-white font-bold"
            // style={{ backgroundColor: colors[calculateColor(initials)] }}
          >
            {initials}
          </span>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-44 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/account"
                  className={classNames(
                    active ? 'bg-gray-100' : '',
                    'block px-4 py-2 text-sm text-gray-700'
                  )}
                >
                  Cuenta & Membresia
                </Link>
              )}
            </Menu.Item>
          </div>
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  type="button"
                  onClick={handleLogout}
                  className={classNames(
                    active ? 'bg-gray-100' : '',
                    'w-full block text-left px-4 py-2 text-sm text-gray-700'
                  )}
                >
                  Cerrar session
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default observer(UserMenu);
