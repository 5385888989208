import { action, observable, makeAutoObservable } from 'mobx';
import { v4 } from 'uuid';

const DEFAULT_STATE = {
  items: []
};

class AlertsStore {
  state = { ...DEFAULT_STATE };

  constructor() {
    makeAutoObservable(this);
  }

  reset = action(() => {
    this.state = { ...DEFAULT_STATE };
  });

  info = action((item) => {
    const observableItem = observable({
      ...item,
      type: 'information',
      key: AlertsStore.generateKey()
    });
    this.state.items.push(observableItem);
  });

  success = action((item) => {
    const observableItem = observable({
      ...item,
      type: 'success',
      key: AlertsStore.generateKey()
    });
    this.state.items.push(observableItem);
  });

  warn = action((item) => {
    const observableItem = observable({
      ...item,
      type: 'warning',
      key: AlertsStore.generateKey()
    });
    this.state.items.push(observableItem);
  });

  close = (alert) =>
    action(() => {
      this.state.items.remove(alert);
    });

  static generateKey() {
    return v4();
  }
}

export default AlertsStore;
