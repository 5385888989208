import { Disclosure } from '@headlessui/react';
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline';
import { useLayoutEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useStores } from '../hooks/use-stores';
import classNames from '../utils/classNames';
import UserMenu from '../Components/UserMenu';

const user = {
  name: 'Lisa Marie',
  email: 'lisamarie@example.com',
  imageUrl:
    'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=80'
};
const navigation = [
  { name: 'Certificados', href: '/reminders/certificates', active: true }
  // { name: 'Inbox', href: '/reminders/x', active: false }
];
const userNavigation = [
  { name: 'Your Profile', href: '#' },
  { name: 'Settings', href: '#' },
  { name: 'Sign out', href: '#' }
];

function AuthenticatedAppLayout({ children }) {
  const { sessionStore, featureStore, alertsStore, certificatesStore } =
    useStores();

  useLayoutEffect(
    () => () => {
      // when in production, reset all stores
      if (process.env.NODE_ENV === 'production') {
        sessionStore.reset();
        certificatesStore.reset();
        featureStore.reset();
        alertsStore.reset();
      }
    },
    [sessionStore.state.authenticated]
  );

  return (
    <>
      <Disclosure
        as="header"
        className="lg:fixed z-10 bg-gradient-to-br from-blue-800 via-blue-700 to-teal-400 shadow w-full"
      >
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:divide-y lg:divide-gray-200 lg:px-8">
              <nav
                className="hidden lg:py-2 lg:flex lg:space-x-8"
                aria-label="Global"
              >
                <span className="text-white font-bold" style={{ fontSize: 24 }}>
                  RΞasy
                </span>
                <div className="flex space-x-4 flex-grow">
                  {navigation.map((item) => (
                    <NavLink
                      key={item.name}
                      to={item.href}
                      className={(navData) =>
                        classNames(
                          navData.isActive
                            ? 'bg-blue-900 bg-opacity-50 text-white'
                            : 'hover:bg-white hover:bg-opacity-10  bg-opacity-0 text-gray-300',
                          'transition-colors rounded-md py-2 px-3 inline-flex items-center text-sm font-medium hover:text-white'
                        )
                      }
                    >
                      {item.name}
                    </NavLink>
                  ))}
                </div>
                <UserMenu />
              </nav>

              <div className="relative z-10 flex items-center lg:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-900">
                  <span className="sr-only">Open menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>

            {/* cuando es mobil */}
            <Disclosure.Panel
              as="nav"
              className="lg:hidden"
              aria-label="Global"
            >
              <div className="pt-2 pb-3 px-2 space-y-1">
                {navigation.map((item) => (
                  <NavLink
                    key={item.name}
                    to={item.href}
                    className="block rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-50 hover:text-gray-900"
                  >
                    {item.name}
                  </NavLink>
                ))}
              </div>
              <div className="border-t border-gray-200 pt-4 pb-3">
                <div className="px-4 flex items-center">
                  <div className="flex-shrink-0">
                    <img
                      className="h-10 w-10 rounded-full"
                      src={user.imageUrl}
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-gray-800">
                      {user.name}
                    </div>
                    <div className="text-sm font-medium text-gray-500">
                      {user.email}
                    </div>
                  </div>
                  <button
                    type="button"
                    className="ml-auto flex-shrink-0 bg-white rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-3 px-2 space-y-1">
                  {userNavigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <main className="max-w-7xl mx-auto pb-8 lg:pt-16 lg:px-8">
        {children}
      </main>
    </>
  );
}

export default AuthenticatedAppLayout;
