import { useEffect, useLayoutEffect } from 'react';
import { TailSpin } from 'react-loading-icons';
import { observer } from 'mobx-react-lite';

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import ContentHeader from '../../Components/ContentHeader';
import { useStores } from '../../hooks/use-stores';
import PrimaryButton from '../../Primitives/PrimaryButton';
import Form from './Form';

const LoadingMessage = observer(() => (
  <div className="flex flex-col mx-auto max-w-xs">
    <h3 className="text-sm font-medium text-gray-900 flex items-center justify-center">
      Por favor, espere
    </h3>
    <p className="mt-1 text-sm text-gray-500 flex items-center justify-center">
      Estamos cargando su cuenta.
    </p>
    <div className="mt-6 flex items-center justify-center">
      <TailSpin width={64} strokeWidth={2} stroke="#BBB" />
    </div>
  </div>
));

const ContentBody = observer(() => {
  const { featureStore } = useStores();

  if (featureStore.state.isFetching) {
    return <LoadingMessage />;
  }

  return <Form />;
});

// function DeleteAccount() {
//   return <PrimaryButton>Cerrar Cuenta</PrimaryButton>;
// }

const cancelWarning = {
  title: 'Lo lamentemos, pero el cambio de su plan no sucedió',
  getBody: () => (
    <span>
      Si require apoyo con la operación, por favor pongase en contacto con
      nuestro equipo de soporte.
    </span>
  )
};

const approvedSuccess = {
  title: 'Felicidades, el cambio del plan fue exitoso',
  body: 'Usted puede aprovechar de nuevas funcionalidades y limites inmediatamente.'
};

function Account() {
  const { featureStore, alertsStore } = useStores();
  const [query, setQuery] = useSearchParams();

  useLayoutEffect(() => {
    featureStore.fetch();
  }, []);

  useEffect(() => {
    if (query.get('canceled') === 'true') {
      alertsStore.warn(cancelWarning);
      query.delete('canceled');
      setQuery(query.toString(), { replace: true });
    }

    if (query.get('success') === 'true') {
      alertsStore.success(approvedSuccess);
      query.delete('success');
      setQuery(query.toString(), { replace: true });
    }
  });

  return (
    <div className="lg:grid lg:grid-cols-12">
      <div className="space-y-6 sm:px-4 lg:px-0 lg:col-span-12">
        <ContentHeader
          title="Cuenta & Membresia"
          description="Aqui usted puede cambiar sus datos personales y la membresia."
        >
          {/* {!featureStore.state.isFetching && <DeleteAccount />} */}
        </ContentHeader>
        <ContentBody />
      </div>
    </div>
  );
}

export default observer(Account);
