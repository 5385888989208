/* eslint-disable no-undef */
/* eslint-disable class-methods-use-this */
import posthog from 'posthog-js';

class ClientEvents {
  initialized = false;

  constructor(posthogApiKey, posthogApiHost) {
    this.posthogApiKey = posthogApiKey;
    this.posthogApiHost = posthogApiHost;
  }

  init = () => {
    if (process.env.NODE_ENV === 'production') {
      posthog.init(this.posthogApiKey, {
        api_host: this.posthogApiHost,
        loaded: () => {
          const isLoggedInCookieValue = ClientEvents.getIsLoggedInCokieValue();
          if (isLoggedInCookieValue) {
            this.identify(isLoggedInCookieValue);
          } else {
            this.reset();
          }

          this.register({
            commit: window.build.commit,
            builtAt: window.build.timestamp
          });
        }
      });
      this.initialized = true;
    }
  };

  identify = (id) => {
    posthog.identify(id);
  };

  reset = () => {
    posthog.reset();
  };

  register = (props) => {
    posthog.register(props);
  };

  static getIsLoggedInCokieValue() {
    const isLoggedInCookieValue = document.cookie
      ?.split('isLoggedIn=')[1]
      ?.split(';')[0];

    return isLoggedInCookieValue;
  }
}

const clientEvents = new ClientEvents(
  'phc_cxxFzp8y4zQmd3O7NKQ8Dpzoo2VDr4Bn2WmgVWx1vEv',
  'https://app.posthog.com'
);

export default clientEvents;
