/* eslint-disable no-undef */
import { action, makeAutoObservable, runInAction } from 'mobx';
import { Link } from 'react-router-dom';
import fuzzy from 'fuzzy';
import http from '../transports/http';

const DEFAULT_STATE = {
  items: [],
  isFetching: false,
  isPosting: false,
  searchText: ''
};

const certificateType2Mex = {
  STAMP: 'SELLO',
  SIGNATURE: 'FIEL'
};

class CertificatesStore {
  state = { ...DEFAULT_STATE };

  constructor() {
    makeAutoObservable(this);
  }

  delete = action((item) => {
    if (window.stores.featureStore.state.canDeleteCertificates) {
      this.state.items.remove(item);
      http.delete(`/reminders/certificates/${item.id}`);
    } else {
      window.stores.alertsStore.warn({
        title: 'En su plan activo, usted no puede borrar certificados.',
        getBody: ({ onClose }) => (
          <>
            Cambie su plan o si no encuentra una opcion viable, pongase en
            contacto con nosotros.
            <div className="mt-4">
              <div className="-mx-2 -my-1.5 flex">
                <Link
                  to="/account"
                  onClick={onClose}
                  className="bg-yellow-50 px-2 py-1.5 rounded-md text-sm font-medium text-yellow-800 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-yellow-50 focus:ring-yellow-600"
                >
                  Cambiar Plan
                </Link>
              </div>
            </div>
          </>
        )
      });
    }
  });

  // eslint-disable-next-line class-methods-use-this
  renew() {
    window.stores.alertsStore.info({
      title: 'Renueva este certificado en el SAT',
      body: (
        <>
          La funcion que usted requiere usar, aun no se ha desarrollado por
          completo.
          <br />
          Por favor intente de nuevo en unos dias, o siguenos en nuestros
          canales oficiales, para recibir una notificacion cuando este listo
          para usarse.
        </>
      )
    });
  }

  create = async (base64Certificate, privateKeyPassword) => {
    this.setPosting(true);
    const [status, newCertificate] = await http.post(
      '/reminders/certificates',
      { base64Certificate, privateKeyPassword }
    );
    this.setPosting(false);

    if (status === 200 && newCertificate) {
      runInAction(() => {
        this.state.items.push(newCertificate);
      });

      return newCertificate;
    }

    return false;
  };

  fetchAll = async () => {
    this.setFetching(true);
    const [status, allCertificates] = await http.get('/reminders/certificates');
    if (status === 200 && allCertificates) {
      runInAction(() => {
        this.state.items = allCertificates;
      });
    }
    this.setFetching(false);
  };

  setFetching = (val) => {
    this.state.isFetching = val;
  };

  setPosting = (val) => {
    this.state.isPosting = val;
  };

  setSearchText = (evt) => {
    this.state.searchText = evt.currentTarget.value;
  };

  get items() {
    if (!this.state.searchText || this.state.searchText === '') {
      return this.state.items;
    }
    const results = fuzzy
      .filter(this.state.searchText, this.state.items, {
        extract: (el) =>
          `${el.name} ${el.serialNumber} ${
            certificateType2Mex[el.certificateType]
          }`
      })
      .map((item) => item.original);
    return results;
  }

  get isLoading() {
    return this.state.isFetching;
  }

  reset = action(() => {
    this.state = { ...DEFAULT_STATE };
  });
}

export default CertificatesStore;
