/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */

// import { PlusIcon } from '@heroicons/react/solid';
import { observer } from 'mobx-react-lite';
import TimeAgo from 'react-timeago';
import spanishStrings from 'react-timeago/lib/language-strings/es';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';

import { useEffect, useLayoutEffect, useMemo } from 'react';
import { TailSpin } from 'react-loading-icons';
import { KeyIcon, TrashIcon } from '@heroicons/react/solid';

import {
  CheckCircleIcon,
  RefreshIcon,
  XCircleIcon
} from '@heroicons/react/outline';
import { runInAction } from 'mobx';
import { Link, useLocation } from 'react-router-dom';
import Table from '../../Components/Table';
import ContentHeader from '../../Components/ContentHeader';
import AddCertificateButton from '../../Components/AddCertificateButton';
import { useStores } from '../../hooks/use-stores';
import validatePassword from '../../Modals/validatePassword';
import http from '../../transports/http';
import classNames from '../../utils/classNames';
import { MenuConfig } from '../../Components/ActionMenu';

const mexicanDate = new Intl.DateTimeFormat('es-MX', {
  year: 'numeric',
  month: 'numeric',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  timeZone: 'UTC'
});

const formatter = buildFormatter(spanishStrings);

const formatDate = (datestamp) => {
  const [date, time] = mexicanDate.format(new Date(datestamp)).split(' ');
  return (
    <>
      {date}
      <br />
      <span className="text-xs text-gray-500">{time}</span>
    </>
  );
};

const certificateType2Mex = {
  STAMP: 'SELLO',
  SIGNATURE: 'FIEL'
};

function EmptyMessage() {
  return (
    <div className="text-center">
      <h3 className="mt-2 text-sm font-medium text-gray-900">
        No hay certificados
      </h3>
      <p className="mt-1 text-sm text-gray-500">
        Empieze ahora, agregando un certificado.
      </p>
      <div className="mt-6">
        <AddCertificateButton />
      </div>
    </div>
  );
}

function LoadingMessage() {
  return (
    <div className="flex flex-col mx-auto max-w-xs">
      <h3 className="text-sm font-medium text-gray-900 flex items-center justify-center">
        Por favor, espere
      </h3>
      <p className="mt-1 text-sm text-gray-500 flex items-center justify-center">
        Estamos cargando sus certificados.
      </p>
      <div className="mt-6 flex items-center justify-center">
        <TailSpin width={64} strokeWidth={2} stroke="#BBB" />
      </div>
    </div>
  );
}

const rowMenuConfig = new MenuConfig([
  {
    key: 'delete-action',
    label: (
      <>
        <TrashIcon
          className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
          aria-hidden="true"
        />
        Borrar
      </>
    ),
    onClick: ({ item, stores }) => {
      stores.certificatesStore.delete(item);
    }
  },
  {
    key: 'refresh-action',
    label: (
      <>
        <RefreshIcon
          className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
          aria-hidden="true"
        />
        Renueva en SAT
      </>
    ),
    validator: ({ item }) => item.isEligibleForAutomaticRenewal === 1,
    onClick: ({ item, stores }) => {
      stores.certificatesStore.renew(item);
    }
  },
  {
    key: 'authorize-renovations',
    label: (
      <>
        <KeyIcon
          className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
          aria-hidden="true"
        />
        Hacer renovable
      </>
    ),
    validator: ({ item }) => item.isEligibleForAutomaticRenewal === 0,
    onClick: async ({ item }) => {
      const [status, response] = await http.get(
        `/reminders/certificates/${item.id}`
      );
      if (status === 200 && response) {
        const password = await validatePassword({
          certificate: response.base64Certificate
        });

        if (password) {
          runInAction(() => {
            item.isEligibleForAutomaticRenewal = 1;
          });
          await http.patch(`/reminders/certificates/${item.id}`, {
            privateKeyPassword: password
          });
        }
      }
    }
  }
]);

const translateStatusLabel = {
  invalid: 'vencido',
  valid: 'valido',
  expiringSoon: 'por vencer'
};

const columns = [
  {
    label: 'Agregado',
    key: 'created_at',
    sortable: true,
    type: 'date',
    template: (cell) => formatDate(cell)
  },
  {
    label: 'Nombre',
    key: 'socialReason',
    sortable: true,
    linebreak: true,
    template: (cell) => cell
  },
  {
    label: 'RFC / SERIE',
    key: 'title',
    sortable: true,
    template: (cell, row) => (
      <div>
        <span className="hover:underline">
          <Link to={`?name=${row.name}`}>{row.name}</Link>
        </span>
        <br />
        <span className="text-xs text-gray-500">{row.serialNumber}</span>
      </div>
    )
  },
  {
    label: 'Tipo',
    key: 'certificateType',
    sortable: true,
    template: (cell) => certificateType2Mex[cell]
  },
  {
    label: 'Estatus',
    key: 'status',
    sortable: true,
    template: (cell, row) => {
      const className = classNames(
        cell === 'valid' ? 'bg-green-100 text-green-800' : '',
        cell === 'invalid' ? 'bg-red-100 text-red-800' : '',
        cell === 'expiringSoon' ? 'bg-yellow-100 text-yellow-800' : '',
        'px-2 inline-flex text-xs leading-5 font-semibold rounded-full'
      );

      return <span className={className}>{translateStatusLabel[cell]}</span>;
    }
  },
  {
    label: 'Fecha Inicio',
    key: 'validFrom',
    sortable: true,
    type: 'date',
    template: (cell) => formatDate(cell)
  },
  {
    label: 'Fecha Fin',
    key: 'validTill',
    sortable: true,
    type: 'date',
    template: (cell) => formatDate(cell)
  },
  {
    label: 'Renovable',
    alignHeader: 'center',
    key: 'isEligibleForAutomaticRenewal',
    sortable: true,
    template: (cell) => (
      <div className="flex items-center justify-center">
        {cell === 1 ? (
          <CheckCircleIcon className="h-6 w-6 text-green-600" />
        ) : (
          <XCircleIcon className="h-6 w-6 text-yellow-500" />
        )}
      </div>
    )
  }
];

function ContentBody() {
  const { certificatesStore } = useStores();

  if (certificatesStore.state.isFetching) {
    return <LoadingMessage />;
  }

  if (certificatesStore.state.items.length === 0) {
    return <EmptyMessage />;
  }

  return (
    <Table
      columns={columns}
      rows={certificatesStore.items.slice()}
      rowKey="id"
      rowMenuConfig={rowMenuConfig}
    />
  );
}

function Content() {
  const { certificatesStore } = useStores();

  rowMenuConfig.onClick = (onClick, row) =>
    onClick({ item: row, stores: { certificatesStore } });

  useLayoutEffect(() => {
    certificatesStore.fetchAll();

    return () => {
      certificatesStore.reset();
    };
  }, []);

  return (
    <div className="relative">
      <ContentHeader
        title="Certificados"
        description="Aqui puede usted hacer gerencia de sus certificados"
        searchPlaceHolder="Buscar certificados"
      >
        {!certificatesStore.state.isFetching &&
          certificatesStore.state.items.length > 0 && <AddCertificateButton />}
      </ContentHeader>
      <ContentBody />
    </div>
  );
}

export default observer(Content);
