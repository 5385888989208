import serialize from 'form-serialize';

const getFormData = (evt) => {
  evt.preventDefault();

  const data = serialize(evt.target, { hash: true, disabled: true });

  return data;
};

export default getFormData;
