/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';

import classNames from '../utils/classNames';

function Button({ children, className = '', ...props }) {
  const classes = classNames(
    className,
    'inline-flex transition-colors items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-white bg-teal-500 disabled:text-gray-400 disabled:bg-gray-300 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500'
  );

  return (
    <button type="button" className={classes} {...props}>
      {children}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

export default Button;
