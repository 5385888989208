import { useContext } from 'react';
import PropTypes from 'prop-types';
import { storesContext, stores } from '../Stores/contexts/storesContext';

export function StoresProvider({ children }) {
  return (
    <storesContext.Provider value={stores}>{children}</storesContext.Provider>
  );
}

StoresProvider.propTypes = {
  children: PropTypes.node
};

export const useStores = () => useContext(storesContext);
