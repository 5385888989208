import { Fragment, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { BriefcaseIcon, MenuIcon, XIcon } from '@heroicons/react/outline';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useStores } from '../hooks/use-stores';
import getFormData from '../utils/getFormData';
import http from '../transports/http';

const navigation = [
  { name: 'Producto', href: '/' },
  { name: 'Características', href: '/' },
  { name: 'Precios', href: '/' },
  { name: 'Soporte', href: '/' }
];

function LandingPage({ children }) {
  const { sessionStore } = useStores();
  const navigate = useNavigate();

  const [formMode, setFormMode] = useState('signUp');

  const toggleFormMode = (newFormMode) => () => {
    setFormMode(newFormMode);
  };

  const handleSignUpFormSubmit = async (evt) => {
    try {
      const newUserData = getFormData(evt);
      await sessionStore.signUpUser(newUserData);
      await sessionStore.signInUser(newUserData);

      navigate('/');
    } catch (ex) {
      console.error(ex);
      evt.target.reset();
      navigate('/signup-error');
    }
  };

  const handleSignInFormSubmit = async (evt) => {
    try {
      const existingUserData = getFormData(evt);
      await sessionStore.signInUser(existingUserData);

      navigate('/');
    } catch (ex) {
      console.error(ex);
      evt.target.reset();
      navigate('/signin-error');
    }
  };

  console.log('rendering landing page');

  return (
    <div className="relative bg-gradient-to-br from-blue-800 via-blue-700 to-teal-400 overflow-hidden h-screen">
      <div
        className="hidden sm:block sm:absolute sm:inset-0"
        aria-hidden="true"
      >
        <svg
          className="absolute bottom-0 right-0 transform translate-x-1/2 mb-48 text-white text-opacity-30 lg:top-0 lg:mt-28 lg:mb-0 xl:transform-none xl:translate-x-0"
          width={364}
          height={384}
          viewBox="0 0 364 384"
          fill="none"
        >
          <defs>
            <pattern
              id="eab71dd9-9d7a-47bd-8044-256344ee00d0"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} fill="currentColor" />
            </pattern>
          </defs>
          <rect
            width={364}
            height={384}
            fill="url(#eab71dd9-9d7a-47bd-8044-256344ee00d0)"
          />
        </svg>
      </div>
      <div className="relative pt-6 pb-16 sm:pb-24">
        <Popover>
          <nav
            className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6"
            aria-label="Global"
          >
            <div className="flex items-center flex-1">
              <div className="flex items-center justify-between w-full md:w-auto">
                <span className="text-white font-bold" style={{ fontSize: 24 }}>
                  RΞasy
                </span>
                <div className="-mr-2 flex items-center md:hidden">
                  <Popover.Button className="bg-slate-700 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-slate-800 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="hidden space-x-10 md:flex md:ml-10">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="font-medium text-white hover:text-gray-300"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
            <div className="hidden md:flex">
              {formMode === 'signUp' ? (
                <button
                  type="button"
                  onClick={toggleFormMode('signIn')}
                  className="inline-flex transition-colors items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-700 hover:bg-blue-900"
                >
                  Ingresar
                </button>
              ) : (
                <button
                  type="button"
                  onClick={toggleFormMode('signUp')}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-700 hover:bg-blue-900"
                >
                  Registrar
                </button>
              )}
            </div>
          </nav>

          <Transition
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
            >
              <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="px-5 pt-4 flex items-center justify-between">
                  <div>
                    <BriefcaseIcon width={38} className="text-slate-400" />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-slate-500">
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="px-2 pt-2 pb-3 space-y-1">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <button
                  type="button"
                  onClick={toggleFormMode('signIn')}
                  className="block w-full px-5 py-3 text-center font-medium text-slate-600 bg-gray-50 hover:bg-gray-100"
                >
                  Ingresar
                </button>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>

        <main className="mt-16 sm:mt-24">
          <div className="mx-auto max-w-7xl">
            <div className="lg:grid lg:grid-cols-12 lg:gap-8">
              <div className="px-4 sm:px-6 sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left lg:flex lg:items-center">
                {formMode === 'signUp' && (
                  <div>
                    <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:leading-none lg:mt-6 lg:text-5xl xl:text-6xl">
                      <span className="md:block">
                        Herramientas para enriquecer
                      </span>{' '}
                      <span className="text-blue-400 md:block">
                        su negocio.
                      </span>
                    </h1>
                    <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                      Deje de luchar en contra de su productividad, ser forzado
                      a usar soluciones de demasiada complejidad y sintiendose
                      olvidado de soporte incapaz de ayudarle.
                    </p>
                  </div>
                )}
                {formMode === 'signIn' && (
                  <div>
                    <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:leading-none lg:mt-6 lg:text-5xl xl:text-6xl">
                      <span className="md:block">Bienvenido</span>{' '}
                      <span className="text-blue-400 md:block">de vuelta.</span>
                    </h1>
                    <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                      Que bueno que regreso usted, nos da valor. Ingrese de
                      nuevo ahora para seguir usando las herramientas que ya
                      ama.
                    </p>
                  </div>
                )}
              </div>
              <div className="mt-16 sm:mt-24 lg:mt-0 lg:col-span-6">
                <div className="bg-white sm:max-w-md sm:w-full sm:mx-auto sm:rounded-lg sm:overflow-hidden">
                  <div className="px-4 py-8 sm:px-10">
                    <div className="mt-2">
                      {formMode === 'signUp' && (
                        <form
                          onSubmit={handleSignUpFormSubmit}
                          className="space-y-6"
                        >
                          <div>
                            <label htmlFor="name" className="sr-only">
                              Nombre completo
                            </label>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              autoComplete="name"
                              placeholder="Nombre completo"
                              required
                              className="block w-full shadow-sm focus:ring-slate-500 focus:border-slate-500 sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>

                          <div>
                            <label htmlFor="email" className="sr-only">
                              Correo electronico
                            </label>
                            <input
                              type="email"
                              name="email"
                              id="email"
                              autoComplete="email"
                              placeholder="Correo electronico"
                              required
                              className="block w-full shadow-sm focus:ring-slate-500 focus:border-slate-500 sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>

                          <div>
                            <label htmlFor="password" className="sr-only">
                              Contrase&ntilde;a
                            </label>
                            <input
                              id="password"
                              name="password"
                              type="password"
                              placeholder="Contrase&ntilde;a"
                              autoComplete="current-password"
                              required
                              className="block w-full shadow-sm focus:ring-slate-500 focus:border-slate-500 sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>

                          <div>
                            <button
                              type="submit"
                              className="w-full transition-colors flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-600"
                            >
                              Crea su Cuenta
                            </button>
                          </div>
                        </form>
                      )}
                      {formMode === 'signIn' && (
                        <form
                          onSubmit={handleSignInFormSubmit}
                          className="space-y-6"
                        >
                          <div className=" grid grid-cols-3 gap-3">
                            <div>
                              <a
                                href={`${http.endpoint}/session/auth/google`}
                                className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                              >
                                <span className="sr-only">
                                  Sign in with Google
                                </span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width="20"
                                  height="20"
                                >
                                  <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
                                    <path
                                      fill="#4285F4"
                                      d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"
                                    />
                                    <path
                                      fill="#34A853"
                                      d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"
                                    />
                                    <path
                                      fill="#FBBC05"
                                      d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"
                                    />
                                    <path
                                      fill="#EA4335"
                                      d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"
                                    />
                                  </g>
                                </svg>
                              </a>
                            </div>
                          </div>
                          <div>
                            <label htmlFor="email" className="sr-only">
                              Correo electronico
                            </label>
                            <input
                              type="email"
                              name="email"
                              id="email"
                              autoComplete="email"
                              placeholder="Correo electronico"
                              required
                              className="block w-full shadow-sm focus:ring-slate-500 focus:border-slate-500 sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                          <div>
                            <label htmlFor="password" className="sr-only">
                              Contrase&ntilde;a
                            </label>
                            <input
                              id="password"
                              name="password"
                              type="password"
                              placeholder="Contrase&ntilde;a"
                              autoComplete="current-password"
                              required
                              className="block w-full shadow-sm focus:ring-slate-500 focus:border-slate-500 sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                          <div>
                            <button
                              type="submit"
                              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"
                            >
                              Ingresar
                            </button>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                  <div className="px-4 py-6 bg-gray-50 border-t-2 border-gray-200 sm:px-10">
                    <p className="text-xs leading-5 text-gray-500">
                      Registrandose, usted esta de acuerdo con nuestros{' '}
                      <a
                        href="/"
                        className="font-medium text-gray-900 hover:underline"
                      >
                        Terminos y Condiciones
                      </a>
                      ,{' '}
                      <a
                        href="/"
                        className="font-medium text-gray-900 hover:underline"
                      >
                        Policia de Datos
                      </a>{' '}
                      y{' '}
                      <a
                        href="/"
                        className="font-medium text-gray-900 hover:underline"
                      >
                        Cookies
                      </a>
                      .
                    </p>
                  </div>
                  {children}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

LandingPage.propTypes = {
  children: PropTypes.node
};

export default LandingPage;
