import { createContext } from 'react';

import SessionStore from '../SessionStore';
import CertificatesStore from '../CertificatesStore';
import FeatureStore from '../FeatureStore';
import AlertsStore from '../AlertsStore';
import ModalsStore from '../ModalsStore';

export const stores = {
  sessionStore: new SessionStore(),
  certificatesStore: new CertificatesStore(),
  featureStore: new FeatureStore(),
  alertsStore: new AlertsStore(),
  modalsStore: new ModalsStore()
};

// eslint-disable-next-line no-undef
window.stores = stores;

export const storesContext = createContext(stores);
