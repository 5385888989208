import { Observer, observer } from 'mobx-react-lite';
import { useRef } from 'react';
import LoadingBar from 'react-top-loading-bar';

import { useStores } from '../hooks/use-stores';
import http from '../transports/http';

import YieldAlerts from './YieldAlerts';
import YieldModals from './YieldModals';

const Authentication = observer(({ children, fallback = null }) => {
  const { sessionStore } = useStores();
  const child = sessionStore.isAuthenticated ? children : fallback();

  const ref = useRef();

  const getRef = () => ref;

  http.setLoader(getRef);

  return (
    <>
      <LoadingBar color="#2dd4bf" ref={ref} height={2} waitingTime={250} />
      <div className="fixed flex justify-center flex-col bottom-0 z-20 w-full">
        <YieldAlerts />
        <YieldModals />
      </div>
      <Observer>{() => child}</Observer>
    </>
  );
});

export default Authentication;
