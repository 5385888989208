/* eslint-disable consistent-return */
import { stores } from '../Stores/contexts/storesContext';

const { modalsStore } = stores;

const validatePassword = ({ certificate }) =>
  new Promise((resolve, reject) => {
    import('./ValidatePasswordModal')
      .then(({ default: ValidatePasswordModal }) => {
        modalsStore.setCurrentModal(
          <ValidatePasswordModal
            certificate={certificate}
            resolve={resolve}
            reject={reject}
          />
        );
      })
      .catch(reject);
  });

export default validatePassword;
